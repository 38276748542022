import { useCommonStore, usePDcenterStore, useAppStore, useUserStore, useAmazonStore } from "@/store"
import { IsAuthorizedByAmazon } from "@/api/account"
import { getAmazonList } from "@/api/common"
import { getUserPermission } from "@/api/ProductCenter/productDashboard"
import { getShareTokenAMC } from "@AMC/api/amc"
import { dayjs } from "@pacvue/element-plus"
import { PacvueGetLanguage } from "@/utils/common-util"
import { isEmpty } from "lodash-es"

const elementLocale = PacvueGetLanguage()
dayjs.locale(elementLocale)

dayjs.updateLocale(elementLocale, {
  weekStart: 0, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
  yearStart: 4 // OPTIONAL, the week that contains Jan 4th is the first week of the year.
})

const excludeRoute = [
  "/oauth2/redirect",
  "/Guide",
  "/Binding",
  "/Account/MyAccount",
  "/login",
  "/login/loginwithsso",
  "loginoauth/redirect",
  "/oauth2/vendorredirect",
  "/Account/Setting",
  "/bind/Setting"
]

export default {
  loginInterceptors: (router) => {
    return new Promise((res) => {
      const PDcenterStore = usePDcenterStore()
      PDcenterStore.SET_CHANNELTYPE(undefined)
      sessionStorage.removeItem("channelType")
      router.push("/Home")
      res(true)
    })
  },
  routerInterceptors: [
    // 授权、引导设置状态
    (to, from, next) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId && !to.query.AMCT) {
            const commonStore = useCommonStore()
            //需要兼容profileId为-10的情况
            const pathName = to.path?.toLowerCase()
            const selectedProfiles = commonStore.selectedProfiles ?? []
            if (pathName != "/campaign/campaigntagging") {
              if (selectedProfiles.length == 1 && selectedProfiles[0] == -10) {
                commonStore.setSelectedProfiles([])
              }
            }
            if (sessionStorage.getItem("AuthData") && sessionStorage.getItem("AuthData") !== "undefined") {
              let aut = JSON.parse(sessionStorage.getItem("AuthData"))
              commonStore.SET_SETTINGSTATE(aut.SettingState)
            } else {
              const AuthData = await IsAuthorizedByAmazon()
              localStorage.removeItem(AuthData.UserId + "_BindProfile") // 授权了有profile
              localStorage.removeItem(AuthData.UserId + "_NoneAuthorized") // 没授权
              localStorage.removeItem(AuthData.UserId + "_SettingState") // 设置setting状态
              if (AuthData.IsAuthorized) {
                localStorage.setItem(AuthData.UserId + "_NoneAuthorized", false)
                if (!AuthData.SettingState) {
                  // 授权了 未设置完成
                  if (AuthData.IsBindProfile) {
                    localStorage.setItem(AuthData.UserId + "_BindProfile", true)
                  } else {
                    localStorage.setItem(AuthData.UserId + "_BindProfile", false)
                  }
                  localStorage.removeItem(AuthData.UserId + "_esaySetting")
                  localStorage.setItem(AuthData.UserId + "_SettingState", AuthData.SettingState)
                  commonStore.SET_SETTINGSTATE(AuthData.SettingState)
                } else {
                  // 授权完成正常进入
                  localStorage.setItem(AuthData.UserId + "_esaySetting", true)
                  localStorage.setItem(AuthData.UserId + "_SettingState", AuthData.SettingState)
                  commonStore.SET_SETTINGSTATE(AuthData.SettingState)
                }
              } else {
                // 未授权
                localStorage.setItem(AuthData.UserId + "_NoneAuthorized", true)
                localStorage.setItem(AuthData.UserId + "_BindProfile", false)
                localStorage.setItem(AuthData.UserId + "_SettingState", AuthData?.SettingState || 0)
                localStorage.removeItem(AuthData.UserId + "_esaySetting")
                if (AuthData?.SettingState) {
                  localStorage.setItem(AuthData.UserId + "_esaySetting", true)
                }
              }
              sessionStorage.setItem("AuthData", JSON.stringify(AuthData))
              commonStore.SET_SETTINGSTATE(AuthData.SettingState)
            }
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    // account 相关
    (to, from, next) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId && !to.query.AMCT) {
            const appStore = useAppStore()
            if (appStore.account?.length === 0) {
              await Promise.all([appStore.accountActionAll({ accountApi: getAmazonList }), appStore.accountAction({ accountApi: getAmazonList })])
            }
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    // profile 相关 (依赖于 account, 故同步序列为 1)
    (to, from, next) => ({
      syncSeq: 1,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId && !to.query.AMCT) {
            const commonStore = useCommonStore()
            if (commonStore.curStore.profileList.length === 0 && !window.inNextGuardLoops) {
              await commonStore.getProfileList()
            }
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    // 偏好及国际化追加逻辑
    (to) => ({
      syncSeq: 1,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId && !to.query.AMCT) {
            const userStore = useUserStore()
            const devZH = localStorage.getItem("dev6ZHSet")
            let i8 = devZH ? devZH : "EN"
            let setting = null
            if (sessionStorage.getItem("setting")) {
              setting = JSON.parse(sessionStorage.getItem("setting"))
            } else {
              setting = userStore.userSettings
            }
            i8 = setting.language
            // if (i18n.mode === "legacy") {
            //   i18n.global.locale = i8
            // } else {
            //   i18n.global.locale.value = i8
            // }
            if (i8 !== localStorage.getItem("locale")) {
              window.prefLangDiffered = true
              localStorage.setItem("locale", i8)
            }

            let elementLocale = "en"
            if (localStorage.getItem("locale") === "ZH") {
              elementLocale = "zh-cn"
            } else if (localStorage.getItem("locale") === "JA") {
              elementLocale = "ja"
            }
            dayjs.updateLocale(elementLocale, {
              weekStart: 0, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
              yearStart: 4 // OPTIONAL, the week that contains Jan 4th is the first week of the year.
            })
            if (window.__inject_pacvue_element_changeLocale__) {
              window.__inject_pacvue_element_changeLocale__({
                option: { locale: { name: elementLocale } }
              })
            }
            if (setting) {
              userStore.SET_USERSETTINGS(setting)
            }
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    (to, from, next) => ({
      syncSeq: 1,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId && !to.query.AMCT) {
            const commonStore = useCommonStore()
            const appStore = useAppStore()
            if (commonStore.curStore.allProfileList.length === 0) {
              if (to.path.toLowerCase() == "/campaign/profile") {
                await commonStore.getAllProfileList({ advertiserIds: appStore.chooseAccounts })
              }
            }
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    // PDCenterStore
    (to, from, next) => ({
      syncSeq: 1,
      async then(onFulfill, onReject) {
        try {
          const appStore = useAppStore()
          const amazonStore = useAmazonStore()
          const PDcenterStore = usePDcenterStore()
          let channelType = PDcenterStore.channelType
          if (!to.query.shareId && !to.query.AMCT && !isEmpty(appStore.chooseAccounts) && (channelType === undefined || channelType === null)) {
            const res = await getUserPermission({
              advertiserIds: appStore.chooseAccounts,
              toMarket: "US"
            })
            channelType = res.type
            appStore.SET_CHANNELTYPE(channelType)
            PDcenterStore.SET_CHANNELTYPE(channelType)
            sessionStorage.setItem("channelType", channelType)

            if (amazonStore.productDashboardChannel === undefined) {
              if (channelType === 1 || channelType === 2) {
                amazonStore.productDashboardChannel = 1
              } else if (channelType === 3) {
                amazonStore.productDashboardChannel = 3
              }
            }
          } else if (amazonStore.productDashboardChannel === undefined) {
            if (PDcenterStore.channelType === 1 || PDcenterStore.channelType === 2) {
              amazonStore.productDashboardChannel = 1
            } else if (PDcenterStore.channelType === 3) {
              amazonStore.productDashboardChannel = 3
            }
          }
          const menuTree = JSON.parse(sessionStorage.getItem("Menu") || "[]")
          let shouldOverwriteMenu = false // 是否需要修改菜单 (结构 / 权限)
          if (!window.menuOverwritten) {
            // 根据 channel type 修改菜单
            if (channelType === 0 || channelType === 1) {
              const productMenu = menuTree.find((item) => item.menuName === "Product Center")
              if (productMenu) {
                shouldOverwriteMenu = true
                productMenu.children = productMenu.children.filter((item) => item.menuName !== "Order")
              }
            }
            // const recommendationsClientList = [
            // US 灰度客户
            // 39, 3034, 3959, 1424, 309, 14, 62, 4043, 4045, 4046, 4049, 4041
            // CN
            // 1, 102018, 101219, 101963, 102091, 102076
            // ]
            // if (!isCN && !recommendationsClientList.includes(userStore.user?.clientId)) {
            //   const optMenu = menuTree.find((item) => item.alias === "Optimization")
            //   const recommendationsMenu = optMenu?.children?.find((item) => item.alias === "recommendation")
            //   if (recommendationsMenu) {
            //     shouldOverwriteMenu = true
            //     recommendationsMenu.permissionType = 0
            //   }
            // }
          }
          if (shouldOverwriteMenu) {
            window.menuOverwritten = true // 确保本次会话不再触发修改
            appStore.SET_MENU(menuTree)
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    // 最终的权限相关拦截
    (to, from, next) => ({
      syncSeq: 2,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId && !to.query.AMCT) {
            const appStore = useAppStore()
            const userStore = useUserStore()
            const isEsaySetting = localStorage.getItem(`${userStore?.user?.userId}_esaySetting`)
            const unauthorized = appStore.accountAll?.length === 0
            const allUnmanaged = appStore.accountAll?.length > 0 && appStore.account?.length === 0
            // 若引导设置完毕, 还去往 /bing/Setting, 则已授权的回到第一个有权限路由, 未授权的去欢迎页
            if (to.path === "/bind/Setting" && isEsaySetting) {
              next(unauthorized ? "/Guide" : window.firstRouter)
            }
            // 若引导未设置完毕, 则拦截通用设置页, 已授权的去引导设置页, 未授权的去欢迎页
            if (to.path === "/Account/Setting" && !isEsaySetting) {
              next(unauthorized ? "/Guide" : "/bind/Setting")
            }
            // 未授权的, 且去向路由在排除范围以外的, 去欢迎页
            if (!excludeRoute.includes(to.path) && unauthorized) {
              next("/Guide")
            }
            // 已授权且未设置完偏好, 且去向路由在排除范围以外的, 去欢迎页
            if ((appStore.account?.length > 0 || allUnmanaged) && !excludeRoute.includes(to.path) && !isEsaySetting) {
              next("/Guide")
            }
            if (
              !isEsaySetting &&
              !userStore?.userSettings?.dateFormat &&
              userStore?.userSettings?.dateFormat != 0 &&
              !excludeRoute.includes(to.path) &&
              to.path != "/Account/Setting" &&
              to.path != "/bind/Setting"
            ) {
              localStorage.setItem(userStore.user.userId + "_SettingState", 0)
              next("/Guide")
            }
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    })
  ],
  shareLinkRouterInterceptors: [
    (to, from, next) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          const searchParams = new URLSearchParams(to.fullPath)
          if (to.path.startsWith("/AMC") && !searchParams?.has("AMCT")) {
            const tokenRes = await getShareTokenAMC({ shareId: to.query.shareId })
            next({ path: to.path, query: { ...to.query, AMCT: tokenRes.Data } })
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    })
  ]
}
