import request from "@pacvue/frame/request"
const VITE_APP_FILTER_COLUMN_URL = import.meta.env.VITE_APP_FILTER_COLUMN_URL
export function addNewColumnPlan(data) {
  return request({
    url: VITE_APP_FILTER_COLUMN_URL + "column/addNewPlan",
    data,
    method: "post"
  })
}

export function getAllColumnPlanDetail(data) {
  //return Promise.resolve([])
  return request({
    url: VITE_APP_FILTER_COLUMN_URL + "column/getAllPlanDetail",
    data,
    method: "post"
  })
}

export function updateColumnPlanDetail(data) {
  return request({
    url: VITE_APP_FILTER_COLUMN_URL + "column/updatePlanDetail",
    data,
    method: "post"
  })
}
export function deleteColumnPlan(data) {
  return request({
    url: VITE_APP_FILTER_COLUMN_URL + "column/deletePlan",
    data,
    method: "post"
  })
}
export function setColumnPlanDefault(data) {
  return request({
    url: VITE_APP_FILTER_COLUMN_URL + "column/updatePlanDetail",
    data,
    method: "post"
  })
}
export function addNewPlan(data) {
  return request({
    url: VITE_APP_FILTER_COLUMN_URL + "filter/addNewPlan",
    data,
    method: "post"
  })
}
export function getAllPlanDetail(data) {
  return request({
    url: VITE_APP_FILTER_COLUMN_URL + "filter/getAllPlanDetail",
    data,
    method: "post"
  })
}
export function updatePlanDetail(data) {
  return request({
    url: VITE_APP_FILTER_COLUMN_URL + "filter/updatePlanDetail",
    data,
    method: "post"
  })
}
export function deletePlan(data) {
  return request({
    url: VITE_APP_FILTER_COLUMN_URL + "filter/deletePlan",
    data,
    method: "post"
  })
}
export function saveLogVersion(data) {
  return request({
    url: VITE_APP_FILTER_COLUMN_URL + "saveLogVersion",
    data,
    method: "post"
  })
}
export function getCustomMetrics(data) {
  return request({
    url: VITE_APP_FILTER_COLUMN_URL + "customMetric/api/getCustomMetrics",
    data,
    method: "post"
  })
}
