import { useCommonStore, useUserStore } from "@/store"
import commonJS from "@pacvue/utils"
import { getCustomMetrics } from "@/api/Advertising"
export function useRouterInterceptor() {
  return {
    async beforeEnter(to, from, next) {
      try {
        const commonStore = useCommonStore()
        const userStore = useUserStore()
        const { clientId, userId } = userStore.user
        const { reportLevel } = JSON.parse(commonJS.decrypt(to.query?.q))
        let hasCustomMetrics = ["Profile", "Campaign"].includes(reportLevel) //, "ASIN", "AdGroup", "Tag", "Keyword", "Query"
        const menuParamsObj = {
          Profile: ["Profile"],
          Campaign: ["Campaign"],
          ASIN: ["ASIN"],
          AdGroup: ["AdGroup"],
          Tag: ["Keyword Tag", "Campaign Tag", "ASIN Tag"],
          Keyword: ["Keyword"],
          Query: ["Search Term"]
        }
        const columnNameObj = {
          Profile: "ProfileReport",
          Campaign: "CampaignReport",
          ASIN: "ASINReport",
          AdGroup: "AdGroupReport",
          Tag: "TagReport",
          Keyword: "KeywordReport",
          Query: "QueryReport"
        }
        if (hasCustomMetrics) {
          try {
            const res = await getCustomMetrics({ productLines: ["amazon"], levels: menuParamsObj[reportLevel], clientId, userId })
            commonStore.setMyReportCustomMetrics({ [columnNameObj[reportLevel]]: res })
          } catch (error) {
            commonStore.setMyReportCustomMetrics([])
          }
        }
      } catch (ex) {
        console.log(ex)
      }
      next()
    }
  }
}
