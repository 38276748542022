import { app, InitApp } from "@pacvue/frame"
import { analyticsPage } from "@pacvue/frame/const"
import permission from "@pacvue/frame/permission.js"
import router from "./router"
import routerFunction from "@pacvue/frame/routerFunction"
import bidexplorer from "@bidexplorerv3/bidexplorer.js"
import ASINCenterAI from "@AsinCenterAIv3/ASINCenterAI.js"
import ruleRouter from "@rule/router.js"
import permissions from "@permission/permission.js"
import eventRouter from "@event/eventRouter.js"
import billingTagRouter from "@billingTag/billingtag.js"
import daypartingRouter from "@dayparting/dayparting"
import Message from "@Message/message.js"
import essentialPlugin from "@pacvue/frame/essentialPlugin"
import Login from "@pacvue/frame/login"
import amazonPlugin from "./amazonPlugin.js"
import budgetRouter from "@BudgetManager/budgetManagerRouter.js"
import campaignAIRouter from "@campaignAI/campaignAIRouter.js"
import AMCRouter from "@AMC/AMCRouter.js"
import recommendationsRouter from "@recommendations/Router.js"
import CalendarCenterRouter from "@hqsov/CalendarCenter/CalendarCenterRouter.js"
// import { i18n } from "@pacvue/frame/i18nEntry"
// import ZH from "@/assets/langs/zh.js"
import { useAppStore, useCommonStore } from "@/store"
import { getRealTimePart } from "./realTimeConfig"
import { initDataDog } from "@pacvue/frame/datadog"
import { initCoralogix } from "@pacvue/frame/coralogix"
import Highcharts from "highcharts/highstock"
import HighchartsMore from "highcharts/highcharts-more"
import Exporting from "highcharts/modules/exporting"
import Annotations from "highcharts/modules/annotations"
// 离线导出功能
import offlineExporting from "highcharts/modules/offline-exporting"
import { isReminderUserToBillingTag } from "@billingTag/reminder.js"
HighchartsMore(Highcharts)
Exporting(Highcharts)
offlineExporting(Highcharts)
Annotations(Highcharts)

// 未来需要删除
var platform = localStorage.getItem("productline")
if (!platform || platform != "amazon" || platform != "walmart") {
  localStorage.setItem("productline", "amazon")
}
// setTimeout(() => {
//   console.log(i18n.global.message)
//   console.log(i18n.global.getLocaleMessage("EN"))
//   i18n.global.setLocaleMessage("EN", ZH)
//   localStorage.setItem("locale", "ZH")
// }, 1000)
InitApp.extend(essentialPlugin)
InitApp.extend(permissions)
InitApp.extend(ASINCenterAI)
InitApp.extend(ruleRouter)
InitApp.extend(bidexplorer)
InitApp.extend(eventRouter)
// InitApp.extend(AMCRouter)
InitApp.extend(campaignAIRouter)
InitApp.extend(AMCRouter)
// InitApp.extend(campaignAIRouter)

InitApp.extend(Message)
InitApp.extend(Login)
InitApp.extend(budgetRouter)
InitApp.extend(daypartingRouter)
InitApp.extend(amazonPlugin)
InitApp.extend(billingTagRouter)
InitApp.extend(recommendationsRouter)
InitApp.extend(CalendarCenterRouter)
InitApp.integrateRouterInterceptors()
permission(router, routerFunction)
InitApp.init(router, "#vm")
const appStore = useAppStore()
const commonStore = useCommonStore()
//配置支持RealTime的页面
appStore.SET_realtimePage(getRealTimePart())

//配置datepicker中props
appStore.SET_DATEPICKEROPTIONS({
  isIsolateRealTime: true //是否需要区分realtime以及custom
})
// 域名配置及后缀
const hostnameConfig = {
  "product.pacvue.com": "us",
  "product.pacvue.cn": "cn",
  "product-eu.pacvue.com": "eu",
  "producteurope.pacvue.com": "eu",
  "product-cdn.pacvue.com": "us",
  "groupm.pacvue.com": "us",
  "groupmeurope.pacvue.com": "eu"
}

// 仅在正式域名下datadog生效
const hostname = window.location.hostname
if (hostname in hostnameConfig && localStorage.getItem("productline") === "amazon") {
  initDataDog(hostnameConfig[hostname])
  initCoralogix(hostnameConfig[hostname])
}
app.config.globalProperties.$eventHub.on("routerAfterEachHook", function ({ to, from, next }) {
  if (!appStore.realtimePage && typeof getRealTimePart === "function") {
    appStore.SET_realtimePage(getRealTimePart())
  }
  analyticsPage({ to, from, next })
  setTimeout(() => {
    const advertiserIds = appStore.account?.map((i) => i.advertiserId) ?? []
    const chooseAccounts = appStore.chooseAccounts || []
    if ((to.path === window.HomePage || to.path === window.firstRouter) && advertiserIds.length > 0 && (chooseAccounts.length === 0 || !chooseAccounts.some((i) => advertiserIds.includes(i)))) {
      // accountDiffHandler: account 选择发生变化时的回调函数
      appStore.SET_CHOOSEACCOUNTS([advertiserIds[0]], { accountDiffHandler: commonStore.CLEAR_CASDADERFILTER })
      sessionStorage.removeItem("profileList")
      window.location.reload()
    }
    isReminderUserToBillingTag(to, from, next)
  }, 500)
})
