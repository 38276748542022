import Main from "@pacvue/frame/layout/Main.vue"
let { MODE } = import.meta.env
// 增加mock模式
if (MODE === "mock") {
  import("@hqsov/mock/index")
}
export default {
  router: {
    path: "/Optimization/Calendar",
    component: Main,
    meta: { auth: true, id: -1 },
    children: [
      {
        path: "/Optimization/Calendar",
        component: () => import("@hqsov/CalendarCenter/index.vue"),
        name: "Calendar Center"
      },
      {
        path: "/Optimization/Calendar/ApplyDetail",
        component: () => import("@hqsov/CalendarCenter/EventApply.vue"),
        name: "Apply Detail",
        meta: {
          parent: "Calendar Center"
        }
      },
      {
        path: "/Optimization/Calendar/create",
        component: () => import("@hqsov/CalendarCenter/EventCalendar/EventCreator/index.vue"),
        name: "Edit Event",
        meta: {
          parent: "Calendar Center"
        }
      },
      {
        path: "/Optimization/Calendar/Log",
        component: () => import("@hqsov/CalendarCenter/Log.vue"),
        name: "CalendarLog",
        meta: {
          parent: "Calendar Center"
        }
      }
    ]
  }
}
