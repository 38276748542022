import request from "@pacvue/frame/request"

const { VITE_APP_RULE_ENGINE, VITE_APP_RULEURL, VITE_APP_META } = import.meta.env

export function getProfiles(data) {
  return request({
    url: VITE_APP_META + "filter/profiles",
    method: "post",
    data
  })
}

export function definition(data = {}) {
  return request({
    url: `${VITE_APP_RULEURL}definition`,
    method: "POST",
    data: data
  })
}
export function automationPauseAsins(data = {}) {
  return request({
    url: `${VITE_APP_RULEURL}automationPauseAsins`,
    method: "POST",
    data: data
  })
}
export function getBrandAndCategory(data) {
  return request({
    url: VITE_APP_META + "apply/getBrandAndCategory",
    method: "POST",
    data
  })
}
export function getRuleList(data) {
  return request({
    url: `${VITE_APP_RULEURL}definition/getRule`,
    method: "post",
    data
  })
}
export function getReview(data) {
  return request({
    url: `${VITE_APP_RULE_ENGINE}/review`,
    method: "post",
    data
  })
}
export function getdefinition(ruleId) {
  return request({
    url: `${VITE_APP_RULEURL}definition/${ruleId}`,
    method: "get"
  })
}
export function getAsinTags(data) {
  return request({
    url: `${VITE_APP_META}apply/asinTags`,
    method: "post",
    data
  })
}
//编辑rule
export function editRule(data = {}) {
  return request({
    url: `${VITE_APP_RULEURL}definition/editRule`,
    method: "POST",
    data: data
  })
}
export function executeEligibility(data) {
  return request({
    url: `${VITE_APP_RULE_ENGINE}/manual/execute/eligibility`,
    method: "POST",
    data: data
  })
}
export function changeRuleState(data) {
  return request({
    url: `${VITE_APP_RULEURL}definition/changeStatus`,
    method: "POST",
    data: data
  })
}
export function eligibilityPage(data) {
  return request({
    url: `${VITE_APP_META}eligibility/page`,
    method: "POST",
    data
  })
}
export function eligibilityVendors(data) {
  return request({
    url: `${VITE_APP_META}eligibility/vendors`,
    method: "POST",
    data
  })
}
export function eligibilityDownload(data) {
  return request({
    url: `${VITE_APP_META}eligibility/download`,
    responseType: "blob",
    method: "POST",
    data
  })
}
export function eligibilitySaveOrUpdate(data) {
  return request({
    url: `${VITE_APP_META}eligibility/saveOrUpdate`,
    method: "POST",
    data
  })
}
export function eligibilityUpload(data) {
  return request({
    url: `${VITE_APP_META}eligibility/upload`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq"
    },
    data
  })
}
export function handleDailyInventory(data) {
  return request({
    url: `${VITE_APP_RULE_ENGINE}/user/handleDailyInventory`,
    method: "POST",
    data
  })
}
export function handleCloseAutomations(data) {
  return request({
    url: `${VITE_APP_RULE_ENGINE}/automations/closeAutomations`,
    method: "POST",
    data
  })
}

export function bulkSetOverrideAsins(data) {
  return request({
    url: `${VITE_APP_META}eligibility/bulkSetOverrideAsins`,
    method: "POST",
    data
  })
}

export function getCommercePermission() {
  return request({
    url: `${VITE_APP_RULEURL}config/getCommercePermission`,
    method: "GET"
  })
}

export function checkRuleName(data) {
  return request({
    url: `${VITE_APP_RULEURL}definition/checkRuleName`,
    method: "POST",
    data: data,
    fullData: true
  })
}

export function get3pProfiles(data) {
  return request({
    url: `${VITE_APP_META}eligibility/get3pProfiles`,
    method: "GET",
    data
  })
}

export function getHave1p3pProfile() {
  return request({
    url: `${VITE_APP_META}apply/have1p3pProfile`,
    method: "GET"
  })
}
